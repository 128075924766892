import Month from '../models/months';
import MonthlyOffer from '../models/monthly-offer';

const offersCollection: MonthlyOffer[] = [
  // ##### TEMPLATE
  // {
  //   month: Month.XX,
  //   year: 2022,
  //   offers: [
  //     {
  //       title: '',
  //       description: '',
  //       imgFile: '.jpg',
  //       offerPrice: 0.0,
  //     },
  //     {
  //       title: '',
  //       description: '',
  //       imgFile: '.jpg',
  //       offerPrice: 0.0,
  //     },
  //     {
  //       title: '',
  //       description: '',
  //       imgFile: '.jpg',
  //       offerPrice: 0.0,
  //     },
  //   ],
  // },
  // #####
  {
    month: Month.July,
    year: 2022,
    offers: [
      {
        title: 'Voltaren forte',
        description: 'Schmerzgel, 100 g',
        imgFile: 'voltaren forte 100g.png',
        offerPrice: 15.99,
      },
      {
        title: 'Imodium akut',
        description: 'bei akutem Durchfall, Hartkapseln, 6 St.',
        imgFile: 'imodium akut.png',
        offerPrice: 4.99,
      },
      {
        title: 'FeniHydrocort 0,5%',
        description: 'bei Hauterkrankungen, Creme, 15 g',
        imgFile: 'fenihydrocort 0,5.png',
        offerPrice: 7.99,
      },
    ],
  },
  {
    month: Month.June,
    year: 2022,
    offers: [
      {
        title: 'Levocetirizin AL',
        description: 'bei Allergien, 5 mg Filmtabletten, 50 St.',
        imgFile: 'levocetirizin_al.png',
        offerPrice: 8.95,
      },
      {
        title: 'Magnesium Verla',
        description: 'Dragées, 50 St.',
        imgFile: 'magnesium_verla.png',
        offerPrice: 4.99,
      },
      {
        title: 'Mykosert Creme',
        description: 'bei Fußpilz,20g',
        imgFile: 'mykosert_creme.png',
        offerPrice: 9.99,
      },
    ],
  },
];

export default offersCollection;
